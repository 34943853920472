var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"parent",staticClass:"field flex align-center",class:[
    {
      'is-focused': _vm.isFocus,
      'has-value': _vm.value,
      'has-error': _vm.errorHint,
      'is-disabled': _vm.isDisabled,
      'is-dark': _vm.dark,
      'no-label': _vm.noLabel
    },
    _vm.inputSize
  ],on:{"click":_vm.focusInput}},[_c('input',_vm._b({ref:"CustomInput",staticClass:"field-input",class:[{ 'no-clear-button': _vm.noClearButton }, _vm.inputClasses],style:([_vm.borderStyle]),attrs:{"id":_vm.$attrs.id,"placeholder":_vm.label,"type":"text","readonly":""},domProps:{"value":_vm.value},on:{"focus":function($event){return _vm.$emit('focus')},"blur":function($event){return _vm.$emit('blur')},"click":function($event){return _vm.$emit('click')}}},'input',_vm.$attrs,false)),(_vm.showFromNow && _vm.rawDateTime)?_c('label',{staticClass:"field-label",class:_vm.errorHint ? 'text-danger' : null,attrs:{"for":_vm.$attrs.id}},[(!_vm.fromNowFormatter)?[_vm._v("("+_vm._s(_vm.moment(_vm.rawDateTime).fromNow())+")")]:_vm._e(),(_vm.fromNowFormatter)?[_vm._v(_vm._s(_vm.fromNowFormatter(_vm.rawDateTime)))]:_vm._e()],2):_vm._e(),(_vm.hasClearButton)?_c('CustomButton',{staticClass:"field-clear-button",attrs:{"color":_vm.dark ? '#757575' : 'rgba(0, 0, 0, 0.54)',"dark":_vm.dark,"round":""},on:{"click":function($event){return _vm.$emit('clear')}}},[_c('span',{staticClass:"fs-16"},[_vm._v(" ✕ ")])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }