<template>
  <div
    ref="parent"
    :class="[
      {
        'is-focused': isFocus,
        'has-value': value,
        'has-error': errorHint,
        'is-disabled': isDisabled,
        'is-dark': dark,
        'no-label': noLabel
      },
      inputSize
    ]"
    class="field flex align-center"
    @click="focusInput"
  >
    <input
      :id="$attrs.id"
      ref="CustomInput"
      v-bind="$attrs"
      :value="value"
      :placeholder="label"
      :style="[borderStyle]"
      type="text"
      class="field-input"
      :class="[{ 'no-clear-button': noClearButton }, inputClasses]"
      readonly
      @focus="$emit('focus')"
      @blur="$emit('blur')"
      @click="$emit('click')"
    />
    <!-- <label
      v-if="!noLabel"
      ref="label"
      :for="$attrs.id"
      :class="errorHint ? 'text-danger' : null"
      :style="[colorStyle]"
      class="field-label"
      @click="focusInput"
    >
      {{ hint || label }}
    </label> -->
    <label
      v-if="showFromNow && rawDateTime"
      class="field-label"
      :for="$attrs.id"
      :class="errorHint ? 'text-danger' : null"
    >
      <template v-if="!fromNowFormatter">({{ moment(rawDateTime).fromNow() }})</template>
      <template v-if="fromNowFormatter">{{ fromNowFormatter(rawDateTime) }}</template>
    </label>
    <CustomButton
      v-if="hasClearButton"
      :color="dark ? '#757575' : 'rgba(0, 0, 0, 0.54)'"
      :dark="dark"
      class="field-clear-button"
      round
      @click="$emit('clear')"
    >
      <span class="fs-16">
        ✕
      </span>
    </CustomButton>
  </div>
</template>

<script>
import CustomButton from './../CustomButton'

export default {
  name: 'CustomInput',
  components: {
    CustomButton
  },
  inheritAttrs: false,
  props: {
    isFocus: { type: Boolean, default: false },
    value: { type: [String, Object], required: false, default: null },
    label: { type: String, default: null },
    noLabel: { type: Boolean, default: false },
    hint: { type: String, default: null },
    errorHint: { type: Boolean, default: null },
    color: { type: String, default: null },
    dark: { type: Boolean, default: false },
    inputSize: { type: String, default: null },
    inputClasses: { type: String, default: null },
    noClearButton: { type: Boolean, default: false },
    showFromNow: { type: Boolean, default: false },
    fromNowFormatter: { type: Function, default: null },
    rawDateTime: { type: String, default: null }
  },
  computed: {
    borderStyle() {
      const cond = this.isFocus && !this.errorHint
      return cond ? { border: `1px solid ${this.color}` } : null
    },
    colorStyle() {
      const cond = this.isFocus
      return cond ? { color: `${this.color}` } : null
    },
    hasClearButton() {
      return !this.noClearButton && !this.isDisabled && this.value
    },
    /**
     * Returns true if the field is disabled
     * @function isDisabled
     * @returns {boolean}
     */
    isDisabled() {
      return typeof this.$attrs.disabled !== 'undefined' && this.$attrs.disabled !== false
    }
  },
  methods: {
    focusInput() {
      this.$refs.CustomInput.focus()
      this.$emit('focus')
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../../../assets/less/variables.less';

.field {
  position: relative;
  &.is-dark {
    .field-label {
      color: @white-light;
    }
    .field-input {
      background-color: transparent;
      border-color: @white-light;
      color: @white-light;
      &.no-border {
        border: none;
      }
    }
    // &.is-disabled {
    //   .field-label,
    //   .field-input {
    //     color: #000;
    //   }
    // }
  }
  &-label {
    position: absolute;
    cursor: pointer;
    right: 50px;
    -webkit-transform: translateY(25%);
    transform: translateY(25%);
    opacity: 0;
    -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    font-size: 14px;
    color: rgba(0, 0, 0, 0.54);
  }
  &-input {
    cursor: pointer;
    background-color: #fff;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    position: relative;
    width: 100%;
    height: 38px;
    min-height: 38px;
    padding-left: 12px;
    padding-right: 44px;
    font-weight: 400;
    -webkit-appearance: none;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.2);
    font-size: 16px;
    z-index: 0;
    &.no-clear-button {
      padding: 0 12px;
    }
  }
  &-clear-button {
    position: absolute;
    right: 12px;
  }
  &.has-error {
    .field-input {
      border-color: orangered;
    }
    .field-label {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0);
      font-size: 14px;
    }
    .field-input {
      padding-top: 14px;
    }
  }
  &.has-value {
    .field-label {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0);
      font-size: 14px;
    }
    &:not(.no-label) {
      .field-input {
        padding-top: 14px;
      }
    }
  }
  &.is-focused {
    .field-input {
      border-color: @orange-light;
    }
    .field-label {
      border-color: @orange-light;
    }
  }
  &.is-disabled {
    // .field-input {
    //   border-color: #ccc;
    //   background: #f2f2f2;
    // }
    .field-label,
    .field-input {
      cursor: default;
    }
  }
  .text-danger {
    color: orangered;
  }
  &.is-dark {
    ::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      color: rgba(255, 255, 255, 0.7);
    }
    :-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: rgba(255, 255, 255, 0.7);
      opacity: 1;
    }
    ::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: rgba(255, 255, 255, 0.7);
      opacity: 1;
    }
    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: rgba(255, 255, 255, 0.7);
    }
    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: rgba(255, 255, 255, 0.7);
    }
    ::placeholder {
      /* Most modern browsers support this now. */
      color: rgba(255, 255, 255, 0.7);
    }
    &.is-disabled {
      ::-webkit-input-placeholder {
        /* WebKit, Blink, Edge */
        color: #424242;
      }
      :-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: #424242;
        opacity: 1;
      }
      ::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: #424242;
        opacity: 1;
      }
      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #424242;
      }
      ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #424242;
      }
      ::placeholder {
        /* Most modern browsers support this now. */
        color: #424242;
      }
    }
  }
  &.sm {
    .field-input {
      height: 36px;
      min-height: 36px;
      font-size: 12px;
    }
    .field-label {
      font-size: 10px;
    }
    &.has-value:not(.no-label) {
      .field-input {
        padding-top: 12px;
      }
    }
  }
  &.lg {
    .field-input {
      height: 48px;
      min-height: 48px;
      font-size: 16px;
    }
    .field-label {
      font-size: 14px;
    }
    &.has-value:not(.no-label) {
      .field-input {
        padding-top: 16px;
      }
    }
  }
}
</style>
