var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"datetimepicker-custom-button flex align-center justify-content-center",class:{
    'is-dark': _vm.dark,
    'with-border': _vm.withBorder,
    'is-hover': _vm.hover,
    'is-selected': _vm.selected,
    round: _vm.round
  },attrs:{"tabindex":"-1","type":"button"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('click')},"focus":function($event){return _vm.$emit('focus')},"blur":function($event){return _vm.$emit('blur')},"mouseover":function($event){return _vm.$emit('mouseover')},"mouseleave":function($event){return _vm.$emit('mouseleave')}}},[_c('span',{staticClass:"datetimepicker-custom-button-effect",style:([_vm.bgStyle])}),_c('span',{staticClass:"datetimepicker-custom-button-content flex align-center justify-content-center",style:([_vm.colorStyle])},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }